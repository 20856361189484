import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Source Sans Pro',
      'sans-serif'
    ].join(','),
    fontSize: 18,
    button: {
      fontSize: '1rem',
      fontWeight: '600',
      textTransform: "none",
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ["Source Sans Pro"],
      },
    },
  },
  palette: {
    primary: {
      light: '#FDE778',
      main: '#FFE358',
      dark: '#FCDA34',
      contrastText: '#373D40',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

export default theme;
